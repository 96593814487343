'use client'

import { Session } from '@supabase/supabase-js'
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs'
import { createContext, Suspense, useContext, useEffect, useState } from 'react'
import { usePathname, useRouter } from 'next/navigation'

const AuthContext = createContext<{
    session: Session | null;
    userId: string | null;
} | undefined>(undefined)

export function AuthProvider({ children }: { children: React.ReactNode }) {
    const [loading, setLoading] = useState(true)
    const [session, setSession] = useState<Session | null>(null)
    const [userId, setUserId] = useState<string | null>(null)
    const router = useRouter()
    const supabase = createClientComponentClient()
    const pathname = usePathname()

    const loadServerSession = async () => {
        const { data: { session } } = await supabase.auth.getSession()
        setSession(session)
        setUserId(session?.user?.id || null)
    }

    function restoreLocalSession() {
        const sessionData = localStorage.getItem('supabase_session')
        if (sessionData) {
            const session = JSON.parse(sessionData)
            setSession(session)
            setUserId(session?.user?.id || null)
        }
    }

    useEffect(() => {
        restoreLocalSession();

        loadServerSession();

        setLoading(false)

        const { data: { subscription } } =
            supabase.auth.onAuthStateChange(
                (event, session) => {
                    setSession(session)
                    setUserId(session?.user?.id || null)

                    if (session) {
                        localStorage.setItem('supabase_session', JSON.stringify(session))
                    } else {
                        localStorage.removeItem('supabase_session')
                    }

                    if (event === 'SIGNED_IN') {
                        if (pathname.startsWith('/login') || pathname.startsWith('/register')) {
                            router.push('/');
                        }
                    }
                }
            )

        return () => {
            subscription.unsubscribe();
        }
    }, [supabase, router])

    if (loading) {
        return null
    }

    return (
        <AuthContext.Provider value={{ session, userId }}>
            <Suspense fallback={null}>
                {children}
            </Suspense>
        </AuthContext.Provider>
    )
}

export const useAuth = () => {
    const context = useContext(AuthContext)
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider')
    }
    return context
}